import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AccountService from '../../services/AccountService';
import { extractError } from '../../helpers/api';

const model = {
    loading: false,
    error: null,
    accountDetails: null,
    message: '',
};

export const getAccountDetails = createAsyncThunk(
    'account/getAccountDetails',
    async (accountId, thunkApi) => {
        try {
            const response = await AccountService.getAccountDetails(accountId);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const updateAccountDetails = createAsyncThunk(
    'account/updateAccountDetails',
    async (data, thunkApi) => {
        try {
            const response = await AccountService.updateAccountDetails(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const accountSlice = createSlice({
    name: 'account',
    initialState: model,
    reducers: {
        resetAccountStateSuccess: (state) => {
            state.message = '';
        }
    },
    extraReducers: (builder) => {
        // get account details
        builder.addCase(getAccountDetails.pending, (state) => {
            state.loading = true;
        }).addCase(getAccountDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.accountDetails = action.payload;
        }).addCase(getAccountDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // update account details
        builder.addCase(updateAccountDetails.pending, (state) => {
            state.loading = true;
        }).addCase(updateAccountDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.accountDetails = action.payload;
            state.message = 'Successfully updated account details!';
        }).addCase(updateAccountDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { resetAccountStateSuccess } = accountSlice.actions;

export default accountSlice.reducer;
