const jwtTokenName = 'accessToken';
const refreshTokenName = 'refreshToken';
const otpTokenName = 'otpToken';
const resetTokenName = 'resetToken';

export const setToken = (token) => {
    localStorage.setItem(jwtTokenName, token);
};

export const setRefreshToken = (refreshToken) => {
    localStorage.setItem(refreshTokenName, refreshToken);
};

export const setOtpToken = (otpToken) => {
    localStorage.setItem(otpTokenName, otpToken);
};

export const setResetToken = (resetToken) => {
    localStorage.setItem(resetTokenName, resetToken);
};

export const getToken = () => localStorage.getItem(jwtTokenName);

export const getRefreshToken = () => localStorage.getItem(refreshTokenName);

export const getOtpToken = () => localStorage.getItem(otpTokenName);

export const getResetToken = () => localStorage.getItem(resetTokenName);

export const removeToken = () => {
    localStorage.removeItem(jwtTokenName);
};

export const removeRefreshToken = () => {
    localStorage.removeItem(refreshTokenName);
};

export const removeOtpToken = () => {
    localStorage.removeItem(otpTokenName);
};

export const removeResetToken = () => {
    localStorage.removeItem(resetTokenName);
};

export const hasTokens = () => getToken() && getRefreshToken();
