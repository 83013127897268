export const updateMetaTitle = (newTitle) => {
    document.title = newTitle;
};

export const updateMetaDescription = (description) => {
    document.querySelector('meta[name="description"]').setAttribute('content', description);
}

export const updateMetaKeywords = (keywords) => {
    document.querySelector('meta[name="keywords"]').setAttribute('content', keywords);
}