import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CatalogService from '../../services/CatalogService';
import { extractError } from '../../helpers/api';
import { getToken } from '../../helpers/handleToken';

const model = {
    catalogCategories: [],
    catalogUrl: [],
    productList: [],
    productLoading: false,
    productListSearch: [],
    productDetails: null,
    loading: false,
    error: null,
    productListState: '',
}

export const getCategories = createAsyncThunk(
    'catalog/getCategories',
    async (_, thunkApi) => {
        try {
            const response = await CatalogService.getCategories();
            const { catalog: catalogCategories, url: urlCategories } = response.data;
            return { catalogCategories, urlCategories };
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getProductList = createAsyncThunk(
    'catalog/getProductList',
    async (data, thunkApi) => {
        let response;
        try {
            const token = getToken();
            if (token) {
                response = await CatalogService.getProductListAsAuthUser(data);
            } else {
                response = await CatalogService.getProductList(data);
            }
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getProductListSearch = createAsyncThunk(
    'catalog/getProductListSearch',
    async (data, thunkApi) => {
        try {
            const response = await CatalogService.getProductListSearch(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const viewProduct = createAsyncThunk(
    'catalog/viewProduct',
    async (data, thunkApi) => {
        let response;
        try {
            const token = getToken();
            if (token) {
                response = await CatalogService.viewProductAsAuthUser(data);
            } else {
                response = await CatalogService.viewProduct(data);
            }
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const catalogSlice = createSlice({
    name: 'catalog',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get category list
        builder.addCase(getCategories.pending, (state) => {
            state.loading = true;
        }).addCase(getCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.catalogCategories = action.payload.catalogCategories;
            state.catalogUrl = action.payload.urlCategories;
        }).addCase(getCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // get product list
        builder.addCase(getProductList.pending, (state) => {
            state.productLoading = true;
        }).addCase(getProductList.fulfilled, (state, action) => {
            state.productList = action.payload;
            state.productListState = action.payload.length > 0 ? 'Has data' : 'No data';
            state.productLoading = false;
        }).addCase(getProductList.rejected, (state, action) => {
            state.error = action.payload;
            state.productLoading = false;
        });

        // get product list (search component)
        builder.addCase(getProductListSearch.pending, (state) => {
            state.loading = true;
        }).addCase(getProductListSearch.fulfilled, (state, action) => {
            state.productListSearch = action.payload;
            state.loading = false;
        }).addCase(getProductListSearch.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });

        // view product
        builder.addCase(viewProduct.pending, (state) => {
            state.loading = true;
        }).addCase(viewProduct.fulfilled, (state, action) => {
            state.productDetails = action.payload;
            state.loading = false;
        }).addCase(viewProduct.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });
    },
});

export default catalogSlice.reducer;
