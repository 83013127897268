import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import NewsService from '../../services/NewsService';
import { extractError } from '../../helpers/api';

const model = {
    loading: false,
    error: null,
    news: [],
    singleNews: null,
    listOfYears: [],
    tags: [],
};

export const getNews = createAsyncThunk(
    'news/getNews',
    async (data, thunkApi) => {
        if (data && (data.search || data.date)) { 
            const {
                search: searchValue,
                date: dateValue,
            } = data;
            try {
                const response = await NewsService.getNewsWithQuery(searchValue, dateValue);
                return response.data;
            }
            catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        } else {
            try {
                const response = await NewsService.getNews();
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        }
    },
);

export const getSingleNews = createAsyncThunk(
    'news/getSingleNews',
    async (newsId, thunkApi) => {
        try {
            const response = await NewsService.getSingleNews(newsId);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getListOfYearsAndTags = createAsyncThunk(
    'news/requestGetListOfYears',
    async (_, thunkApi) => {
        try {
            const response = await NewsService.getListOfYearsAndTags();
            const {
                tags: tagList,
                years: yearList,
            } = response.data;
            return { tagList, yearList };
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const newsSlice = createSlice({
    name: 'news',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get news
        builder.addCase(getNews.pending, (state) => {
            state.loading = true;
        }).addCase(getNews.fulfilled, (state, action) => {
            state.loading = false;
            state.news = action.payload;
        }).addCase(getNews.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get single news
        builder.addCase(getSingleNews.pending, (state) => {
            state.loading = true;
        }).addCase(getSingleNews.fulfilled, (state, action) => {
            state.loading = false;
            state.singleNews = action.payload;
        }).addCase(getSingleNews.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get list of years
        builder.addCase(getListOfYearsAndTags.pending, (state) => {
            state.loading = true;
        }).addCase(getListOfYearsAndTags.fulfilled, (state, action) => {
            state.loading = false;
            state.tags = action.payload.tagList;
            state.listOfYears = action.payload.yearList;
        }).addCase(getListOfYearsAndTags.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default newsSlice.reducer;
