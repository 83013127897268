import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../redux/authentication/authSlice';
import catalogSlice from '../redux/catalog/catalogSlice';
import contactSlice from '../redux/contact/contactSlice';
import crossReferenceSlice from '../redux/cross_reference/crossReferenceSlice';
import dynamicDataSlice from '../redux/dynamic_data/dynamicDataSlice';
import menuSlice from '../redux/menu/menuSlice';
import newsSlice from '../redux/news/newsSlice';
import staticDataSlice from '../redux/static_data/staticDataSlice';
import generalSlice from '../redux/general/generalSlice';
import addressSlice from '../redux/address/addressSlice';
import accountSlice from '../redux/account/accountSlice';
import favoritesSlice from '../redux/favorites/favoritesSlice';
import dashboardSlice from '../redux/dashboard/dashboardSlice';
import appSlice from '../redux/app/appSlice';
import submittalSlice from '../redux/submittal/submittalSlice';

const store = configureStore({
    reducer: {
        app: appSlice,
        authentication: authSlice,
        catalog: catalogSlice,
        contact: contactSlice,
        crossReference: crossReferenceSlice,
        dynamicData: dynamicDataSlice,
        menu: menuSlice,
        news: newsSlice,
        staticData: staticDataSlice,
        general: generalSlice,
        address: addressSlice,
        account: accountSlice,
        favorites: favoritesSlice,
        dashboard: dashboardSlice,
        submittal: submittalSlice,
    },
});

export default store;
