import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Menu } from 'antd';
import HeaderFixed from '../../components/MainLayout/HeaderFixed';
import privateRoutes from '../../routes/privateRoutes';
import { getDefaultPath } from '../../helpers/url_sync';
import { logout } from '../../redux/authentication/login/loginSlice';

function UserPanel({ children }) {
    const location = useLocation();
    const dispatch = useDispatch();

    const [selectedKeys, setSelectedKeys] = useState(getDefaultPath());
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const submittalId = useSelector((state) => state.submittal.view.data?.submittal_data?.submittal_id);

    const items = privateRoutes.map((route) => ({
        key: route.key,
        icon: route.icon,
        label: route.accessor === 'log-out'
            ? <Link onClick={() => dispatch(logout())}>{route.name}</Link>
            : <Link to={`/user-panel/${route.accessor}`}>{route.name}</Link>
    }));

    useEffect(() => {
        setCurrentPath(location.pathname);
        setSelectedKeys(getDefaultPath());
    }, [location.pathname]);

    const shouldRenderMenu = !(currentPath.includes('/new') || currentPath.includes('/edit') || currentPath.includes(`${submittalId}`));

    return (
        <div className="user-panel">
            <HeaderFixed activeTab="user-panel" />
            <div className="user-panel-main-content">
                <Row gutter={[40, 30]} justify="space-between">
                    {shouldRenderMenu && (
                        <Col lg={8} sm={8} xs={24}>
                            <Menu
                                className="dashboard-menu"
                                mode="inline"
                                selectedKeys={selectedKeys}
                                onSelect={({ key }) => setSelectedKeys([key])}
                                items={items}
                            />
                        </Col>
                    )}
                    <Col lg={shouldRenderMenu ? 16 : 24} sm={shouldRenderMenu ? 16 : 24} xs={24}>
                        {children}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default UserPanel;
