import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BoxDarkIcon, BoxIcon, RemoveIcon } from '../../assets/banner';
import { MetroChevronThinRightIcon} from '../../assets/icons';
import ProductImg from '../../assets/products/ab9400-5.png';
import {
    setIsCartOpenFromAddToProjectBtn,
} from '../../redux/app/appSlice';
import { getProductsInCart, removeProductFromCart } from '../../helpers/handleCartOfProducts';
import {
    getSubmittalId,
    setFormStep,
} from '../../helpers/handleFormStep';
import { setReduxFormStep, setProductsToReduxCart } from '../../redux/submittal/create/createSlice';

function Cart({
    usedFor,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submittalId = getSubmittalId();

    const isCartOpenFromAddToProjectBtn = useSelector((state) => state.app.isCartOpenFromAddToProjectBtn);
    const productsInCart = useSelector((state) => state.submittal.create.productsInCart);

    const [isOpen, setIsOpen] = useState(false);

    const handleRemoveProductFromCart = (product) => {
        removeProductFromCart(product);
        dispatch(setProductsToReduxCart(getProductsInCart()));
    };

    const toggleCart = (event) => {
        if (isCartOpenFromAddToProjectBtn) {
            setIsOpen(false);
            dispatch(setIsCartOpenFromAddToProjectBtn(false));
        } else {
            setIsOpen(!isOpen);
        }
        if (productsInCart.length === 0) {
            setIsOpen(false);
        }
        // Ensures that the click event does not propagate to any parent elements
        event.stopPropagation();
    };

    // Stop the click event from propagating to the document, which prevents the cart from closing immediately
    const handleCartModalClick = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const handleDocumentClick = () => {
            setIsOpen(false);
            if (isCartOpenFromAddToProjectBtn) dispatch(setIsCartOpenFromAddToProjectBtn(false));
        };

        // Add click event listener to the document to close the cart when clicking outside any cart
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [isCartOpenFromAddToProjectBtn]);

    return (
        <div className={`cart ${isOpen || isCartOpenFromAddToProjectBtn ? 'cart-opened' : ''}`} onClick={toggleCart}>
            <div className="cart-tooltip">
                Submittal Packages
            </div>
            <div className="cart-count empty">
                {productsInCart?.length}
            </div>
            {usedFor === "fixed-header" ? (
                <BoxDarkIcon />
            ) : (
                <BoxIcon />
            )}
            <div className="cart-modal d-flex"  onClick={handleCartModalClick}>
                <div className="cart-body d-flex">
                    {productsInCart?.map((product, i) => (
                        <div className="cart-item" key={i}>
                            <div className="image">
                                <img src={product.image_url || product.image[0]?.image_url} alt="product" />
                            </div>
                            <div className="desc">
                                <div className="title">{product.product_name}</div>
                                <div className="category">{product.category_name}</div>
                                {/* in case of not having category */}
                                {/* also dont include view full details in this case */}
                                {/* <div className="no-variations">
                                    <div>
                                        <div className="icon">i</div>
                                    </div> <span>There are no variations for this product</span>
                                </div> */}
                                <div className="view-full">
                                    {/*  not sure if this is needed */}
                                    {/* <a href="">View full details</a> */}
                                </div>
                            </div>
                            <div
                                className="remove"
                                role="button"
                                onClick={() => handleRemoveProductFromCart(product)}
                            >
                                <RemoveIcon />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="cart-footer d-flex">
                    <button
                        className="view view-project-btn"
                        onClick={() => {
                            if (submittalId) {
                                navigate(`/user-panel/submittal-manager/${submittalId}/edit`);
                            } else {
                                navigate('/user-panel/submittal-manager/new');
                            }
                            setFormStep(1);
                            dispatch(setReduxFormStep(1));
                            setIsOpen(false);
                            dispatch(setIsCartOpenFromAddToProjectBtn(false));
                        }}
                    >
                        <span>View Project Summary</span>
                        <MetroChevronThinRightIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Cart;
