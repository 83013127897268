import { lazy } from 'react';
import {
    HomeIcon,
    SubmittalIcon,
    QuotesIcon,
    FavoritesIcon,
    AddressIcon,
    AccountIcon,
    LogOutIcon,
} from '../assets/userpanel';

const Dashboard = lazy(() => import('../screens/UserPanel/Dashboard'));
const SubmittalManager = lazy(() => import('../screens/UserPanel/SubmittalManager'));
const Quotes = lazy(() => import('../screens/UserPanel/Quotes'));
const Favorites = lazy(() => import('../screens/UserPanel/Favorites'));
const Address = lazy(() => import('../screens/UserPanel/Address'));
const AccountDetails = lazy(() => import('../screens/UserPanel/Account'));

export default [
    {
        path: '/dashboard',
        key: 'DASHBOARD',
        component: Dashboard,
        name: 'Dashboard',
        icon: <HomeIcon />,
        accessor: 'dashboard',
    },
    {
        path: '/submittal-manager/*',
        key: 'SUBMITTAL_MANAGER',
        component: SubmittalManager,
        name: 'Submittal Manager',
        icon: <SubmittalIcon />,
        accessor: 'submittal-manager',
    },
    {
        path: '/quotes',
        key: 'QUOTES',
        component: Quotes,
        name: 'Quotes',
        icon: <QuotesIcon />,
        accessor: 'quotes',
    },
    {
        path: '/favorites',
        key: 'FAVORITES',
        component: Favorites,
        name: 'Favorites',
        icon: <FavoritesIcon />,
        accessor: 'favorites',
    },
    {
        path: '/addresses',
        key: 'ADDRESSES',
        component: Address,
        name: 'Addresses',
        icon: <AddressIcon />,
        accessor: 'addresses',
    },
    {
        path: '/account-details',
        key: 'ACCOUNT_DETAILS',
        component: AccountDetails,
        name: 'Account Details',
        icon: <AccountIcon />,
        accessor: 'account-details',
    },
    {
        path: '',
        key: 'LOG_OUT',
        component: () => null,
        name: 'Logout',
        icon: <LogOutIcon />,
        accessor: 'log-out',
    }
];
