import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FavoriteService from '../../services/FavoriteService';
import { extractError } from '../../helpers/api';

const model = {
    loading: false,
    error: null,
    favoriteList: [],
    favoriteProductIds: [],
    categoryList: [],
    totalItems: 0,
    message: '',
};

export const getFavoriteList = createAsyncThunk(
    'favorite/getFavoriteList',
    async (data, thunkApi) => {
        try {
            const response = await FavoriteService.getFavoriteList(data);
            return response.data;
        }
        catch (error) {
            thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const addOrRemoveFavorite = createAsyncThunk(
    'favorite/addOrRemoveFavorite',
    async (data, thunkApi) => {
        try {
            const response = await FavoriteService.addOrRemoveFavorite(data);
            return {
                requestData: data.favorites,
                responseData: response.data,
            };
        }
        catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getTreeCategoryList = createAsyncThunk(
    'favorite/getCategoryList',
    async (_, thunkApi) => {
        try {
            const response = await FavoriteService.getTreeCategoryList();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const favoritesSlice = createSlice({
    name: 'favorites',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get favorite list
        builder.addCase(getFavoriteList.pending, (state) => {
            state.loading = true;
        }).addCase(getFavoriteList.fulfilled, (state, action) => {
            state.loading = false;
            state.favoriteList = action.payload.favorites;
            state.totalItems = action.payload.total;
        }).addCase(getFavoriteList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // add or remove from favorites
        builder.addCase(addOrRemoveFavorite.pending, (state) => {
            state.loading = true;
        }).addCase(addOrRemoveFavorite.fulfilled, (state, action) => {
            state.loading = false;
            state.favoriteList = action.payload.responseData.length < 1
                ? state.favoriteList.filter((item) => !action.payload.requestData.some((el) => el.favorite_id === item.id))
                : [...state.favoriteList, ...action.payload.responseData];
            state.message = action.payload.responseData.length < 1 ? 'Removed from favorites' : 'Added to favorites';
            state.favoriteProductIds = [...state.favoriteProductIds, action.payload.requestData[0].product_id];
        }).addCase(addOrRemoveFavorite.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get category list
        builder.addCase(getTreeCategoryList.pending, (state) => {
            state.loading = true;
        }).addCase(getTreeCategoryList.fulfilled, (state, action) => {
            state.loading = false;
            state.categoryList = action.payload;
        }).addCase(getTreeCategoryList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default favoritesSlice.reducer;
