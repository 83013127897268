const productsInCart = 'productsInCart';

export const setProductsInCart = (newProducts) => {
    let existingProducts = localStorage.getItem(productsInCart) ? JSON.parse(localStorage.getItem(productsInCart)) : [];

    newProducts.forEach((newProduct) => {
        const productExists = existingProducts.some((existingProduct) => 
            existingProduct.product_id === newProduct.product_id && 
            existingProduct.category_id === newProduct.category_id
        );

        if (!productExists) {
            existingProducts.push(newProduct);
        }
    });

    localStorage.setItem(productsInCart, JSON.stringify(existingProducts));
}

export const getProductsInCart = () => JSON.parse(localStorage.getItem(productsInCart));

export const removeProductFromCart = (product) => {
    const existingProducts = JSON.parse(localStorage.getItem(productsInCart));

    const updatedProducts = existingProducts.filter((existingProduct) => 
        !(existingProduct.product_id === product.product_id && existingProduct.category_id === product.category_id)
    );
    localStorage.setItem(productsInCart, JSON.stringify(updatedProducts));
}

function addNewOption(options, newOption) {
    const optionExists = options.some((option) => option.id === newOption.id);
    if (optionExists) {
        return options;
    }
    return [...options, newOption];
};

export const updateProductProperties = (products, columnType, record, inputValue) => {
    const updatedProducts = products.map((item) => {
        if (columnType === 'length') {
            return item.product_id === record.product_id && item.category_id === record.category_id
                ? {
                    ...item,
                    cut_lengths: addNewOption(item.cut_lengths, { id: inputValue, cut_length: inputValue }),
                }
                : item;
        } else {
            return item.product_id === record?.product_id && item.category_id === record.category_id
                ? {
                    ...item,
                    material_types: addNewOption(item.material_types, { id: inputValue, material_type: inputValue }),
                }
                : item;
        }
    })
    localStorage.setItem(productsInCart, JSON.stringify(updatedProducts));
}

export const updateListOfProducts = (products) => {
    localStorage.setItem(productsInCart, JSON.stringify(products));
}

export const clearProductsInCart = () => {
    localStorage.removeItem(productsInCart);
}