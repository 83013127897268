import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GeneralService from '../../services/GeneralService';
import RegisterService from '../../services/AuthServices/RegisterService';
import { extractError } from '../../helpers/api';

const model = {
    loading: false,
    countryList: [],
    companyList: [],
    error: null,
};

export const getCountryListOptions = createAsyncThunk(
    'customer/getCountryList',
    async (data, { rejectWithValue }) => {
        try {
            const { type: serviceType } = data;
            let response;
            if (serviceType === 'register') {
                response = await RegisterService.getCountryListOptions();
            } else {
                response = await GeneralService.getCountryListOptions();
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(extractError(error));
        }
    },
);

export const getCompanyListOptions = createAsyncThunk(
    'customer/getCompanyListOptions',
    async (data, { rejectWithValue }) => {
        try {
            const { type: serviceType } = data;
            let response;
            if (serviceType === 'register') {
                response = await RegisterService.getCompanyListOptions();
            } else {
                response = await GeneralService.getCompanyListOptions();
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(extractError(error));
        }
    },
);

const generalSlice = createSlice({
    name: 'general',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get country list options
        builder.addCase(getCountryListOptions.pending, (state) => {
            state.loading = true;
        }).addCase(getCountryListOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.countryList = action.payload;
        }).addCase(getCountryListOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // get company list options
        builder.addCase(getCompanyListOptions.pending, (state) => {
            state.loading = true;
        }).addCase(getCompanyListOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.companyList = action.payload;
        }).addCase(getCompanyListOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default generalSlice.reducer;
