const formStepName = 'formStep';
const newProjectName = 'isNewProject';
const editProjectName = 'isEditProject';
const projectIdName = 'projectId';
const submittalIdName = 'submittalId';
const submittalDataName = 'submittalData';
const projectDataName = 'projectData';
const customerDataName = 'customerData';
const submissionTypeName = 'submissionType';
const isConvertName = 'isConvert';
const requestAQuoteName = 'requestAQuote';
const duplicateQuoteName = 'duplicateQuote';
const duplicateSubmissionName = 'duplicateSubmission';
const isEditSubmittalName = 'isEditSubmittal';
const formDataName = 'formData';

export const setFormStep = (step) => {
    localStorage.setItem(formStepName, step);
};

export const getFormStep = () => JSON.parse(localStorage.getItem(formStepName));

export const clearFormStep = () => {
    localStorage.removeItem(formStepName);
};

export const setIsNewProject = (isNewProject) => {
    localStorage.setItem(newProjectName, isNewProject);
};

export const getIsNewProject = () => JSON.parse(localStorage.getItem(newProjectName));

export const clearIsNewProject = () => {
    localStorage.removeItem(newProjectName);
};

export const setIsEditProject = (isEditProject) => {
    localStorage.setItem(editProjectName, isEditProject);
};

export const getIsEditProject = () => JSON.parse(localStorage.getItem(editProjectName));

export const clearIsEditProject = () => {
    localStorage.removeItem(editProjectName);
};

export const setProjectId = (projectId) => {
    localStorage.setItem(projectIdName, projectId);
};

export const getProjectId = () => localStorage.getItem(projectIdName);

export const clearProjectId = () => {
    localStorage.removeItem(projectIdName);
};

export const setProjectData = (projectData) => {
    localStorage.setItem(projectDataName, JSON.stringify(projectData));
};

export const getProjectData = () => JSON.parse(localStorage.getItem(projectDataName));

export const clearProjectData = () => {
    localStorage.removeItem(projectDataName);
};

export const setSubmittalId = (submittalId) => {
    localStorage.setItem(submittalIdName, submittalId);
};

export const getSubmittalId = () => localStorage.getItem(submittalIdName);

export const clearSubmittalId = () => {
    localStorage.removeItem(submittalIdName);
};

export const setSubmittalData = (submittalData) => {
    localStorage.setItem(submittalDataName, JSON.stringify(submittalData));
};

export const getSubmittalData = () => JSON.parse(localStorage.getItem(submittalDataName));

export const clearSubmittalData = () => {
    localStorage.removeItem(submittalDataName);
};

export const setCustomerData = (customerData) => {
    localStorage.setItem(customerDataName, JSON.stringify(customerData));
};

export const getCustomerData = () => JSON.parse(localStorage.getItem(customerDataName));

export const clearCustomerData = () => {
    localStorage.removeItem(customerDataName);
};

export const setSubmissionType = (submissionType) => {
    localStorage.setItem(submissionTypeName, submissionType);
};

export const getSubmissionType = () => localStorage.getItem(submissionTypeName);

export const clearSubmissionType = () => {
    localStorage.removeItem(submissionTypeName);
};

export const setIsConvert = (isConvert) => {
    localStorage.setItem(isConvertName, isConvert);
};

export const getIsConvert = () => JSON.parse(localStorage.getItem(isConvertName));

export const clearIsConvert = () => {
    localStorage.removeItem(isConvertName);
};

export const setRequestAQuote = (requestAQuote) => {
    localStorage.setItem(requestAQuoteName, requestAQuote);
};

export const getRequestAQuote = () => JSON.parse(localStorage.getItem(requestAQuoteName));

export const clearRequestAQuote = () => {
    localStorage.removeItem(requestAQuoteName);
};

export const setDuplicateQuote = (duplicateQuote) => {
    localStorage.setItem(duplicateQuoteName, duplicateQuote);
};

export const getDuplicateQuote = () => JSON.parse(localStorage.getItem(duplicateQuoteName));

export const clearDuplicateQuote = () => {
    localStorage.removeItem(duplicateQuoteName);
};

export const setDuplicateSubmission = (duplicateSubmission) => {
    localStorage.setItem(duplicateSubmissionName, duplicateSubmission);
};

export const getDuplicateSubmission = () => JSON.parse(localStorage.getItem(duplicateSubmissionName));

export const clearDuplicateSubmission = () => {
    localStorage.removeItem(duplicateSubmissionName);
};

export const setIsEditSubmittal = (isEditSubmittal) => {
    localStorage.setItem(isEditSubmittalName, isEditSubmittal);
};

export const getIsEditSubmittal = () => JSON.parse(localStorage.getItem(isEditSubmittalName));

export const clearIsEditSubmittal = () => {
    localStorage.removeItem(isEditSubmittalName);
};

export const setFormData = (formData) => {
    localStorage.setItem(formDataName, JSON.stringify(formData));
};

export const getFormData = () => JSON.parse(localStorage.getItem(formDataName));

export const clearFormData = () => {
    localStorage.removeItem(formDataName);
};