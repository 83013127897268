import { createSlice } from '@reduxjs/toolkit';

const model = {
    isCartOpenFromAddToProjectBtn: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState: model,
    reducers: {
        setIsCartOpenFromAddToProjectBtn: (state, action) => {
            state.isCartOpenFromAddToProjectBtn = action.payload;
        },
    },
    extraReducers: (builder) => {},
});

export const {
    setIsCartOpenFromAddToProjectBtn,
} = appSlice.actions;

export default appSlice.reducer;
