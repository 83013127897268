import BaseService from '../../services/BaseService';

class LoginService extends BaseService {
    loginFirstStep = (data) => this.post('/login', data);

    verify2faOtp = (data) => this.post('/verify-2fa-otp', data);

    verifyEmail = (data) => this.post('/verify-email-otp', data);

    loginResendOtp = (data) => this.post('/resend-2fa-otp', data);

    resendVerifyEmailOtp = (data) => this.post('/resend-verify-email-otp', data);

    logout = () => this.get('/logout');
}

export default new LoginService();
