import menu from '../routes/privateRoutes';

export function getDefaultPath() {
    const getParent = ((route) => {
        const parent = [];
        if (!route) return parent;
        menu.forEach((option) => {
            if (route.match(option.accessor)) {
                parent.push(option.accessor.replace('-', '_').toUpperCase());
            }
        });
        return parent;
    });

    const route = window.location.pathname;
    if (route) {
        return getParent(route);
    }
    return [];
}
