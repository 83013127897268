import BaseService from './BaseService';

class DynamicDataService extends BaseService {
    getSolutions = () => this.get('/solutions');
    
    getTeamMembers = () => this.get('/team-members');
    
    getCertifications = () => this.get('/certifications');

    getFaqsWithQuery = (search) => this.get(`/faqs?id=${search}`);
    
    getFaqs = () => this.get('/faqs');

    getFaqSuggestions = (search) => this.get(`/get-faqs-suggestions?search=${search}`);
    
    getLiteratureWithQuery = (search) => this.get(`/get-literature-data?search=${search}`);
    
    getLiterature = () => this.get('/get-literature-data');
    
    getLiteratureSuggestions = (search) => this.get(`/get-literature-suggestions?search=${search}`);
    
    getTechnicalDataWithQuery = (search) => this.get(`/get-technical-data?search=${search}`);
    
    getTechnicalData = () => this.get('/get-technical-data');
    
    getTechnicalDataSuggestions = (search) => this.get(`/get-technical-data-suggestions?search=${search}`);
    
    downloadPdf = (data) => this.post('/download-request', data);
}

export default new DynamicDataService();
