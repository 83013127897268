export const replaceUpdatedItem = (items, updatedItem, identityKey = 'id') => items.map(
    (item) => (
        (item[identityKey] === updatedItem[identityKey])
            ? updatedItem
            : item
    ),
);

export const removeSelectedItem = (items, deletedItemId, identityKey = 'id') => items.filter((item) => item[identityKey] !== deletedItemId);

export const upseartItem = (existingItems, newItem) => {
    const itemsAlreadyExists = existingItems.findIndex(
        (existingItem) => existingItem.id === newItem.id,
    ) !== -1;
    if (itemsAlreadyExists) {
        return replaceUpdatedItem(existingItems, newItem);
    } else {
        return [newItem, ...existingItems];
    }
};

export const replaceStatusChangedItem = (items, updatedItemId, newStatus) => items.map(
    (item) => (
        (item.id === updatedItemId)
            ? { ...item, status: newStatus }
            : item
    ),
);
