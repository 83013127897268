import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AppRouter from './routes';
import DefaultLoading from './components/DefaultLoading';
import { getStaticData } from './redux/static_data/staticDataSlice';
import {
    getMenu,
    getMenuCategories,
} from './redux/menu/menuSlice';
import { getSolutions, getCertifications } from './redux/dynamic_data/dynamicDataSlice';
import { checkAuthorization } from './redux/authentication/login/loginSlice';

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkAuthorization());
        dispatch(getStaticData());
        dispatch(getMenu());
        dispatch(getMenuCategories());
        dispatch(getSolutions());
        dispatch(getCertifications());
    }, [dispatch]);

    return (
        <Suspense fallback={<DefaultLoading />}>
            <AppRouter />
        </Suspense>
    );
}

export default App;
