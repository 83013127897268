import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayout from '../components/MainLayout';
import NotFound from '../screens/Authentication/NotFound';
import UserPanel from '../screens/UserPanel';
import publicRoutes from './publicRoutes';
import privateRoutes from './privateRoutes';

const SubmittalManagerNew = lazy(() => import('../screens/UserPanel/SubmittalManager/New'));

function AppRouter() {
    const isAuthenticated = useSelector((state) => state.authentication.login.isAuthenticated);
    const emailVerified = useSelector((state) => state.authentication.register.emailVerified);
    const formStep = useSelector((state) => state.submittal.create.step);

    return (
        <Router>
            <MainLayout>
                <Routes>
                    {publicRoutes.map((route) => (
                        <Route
                            key={route.key}
                            path={route.path}
                            element={<route.component />}
                        />
                    ))}
                    <Route
                        // exact
                        path="/user-panel"
                        element={<Navigate to="/user-panel/dashboard" />}
                    />
                    <Route
                        path="/user-panel/*"
                        element={
                            <UserPanel>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Routes>
                                        {formStep && formStep === 1 ? (
                                            <Route
                                                path="/submittal-manager/new"
                                                element={<SubmittalManagerNew />}
                                            />
                                        ) : (
                                            <Route
                                                path="*"
                                                element={<Navigate to="/login" />}
                                            />
                                        )}
                                        {isAuthenticated || emailVerified
                                            ? (
                                                privateRoutes.map((route) => (
                                                    <Route
                                                        key={route.key}
                                                        path={route.path}
                                                        element={<route.component />}
                                                    />
                                                ))
                                            ) : (
                                                <Route
                                                    // * is a wildcard that matches everything
                                                    path="*"
                                                    element={<Navigate to="/login" />}
                                                />
                                            )
                                        }
                                    </Routes>
                                </Suspense>
                            </UserPanel>
                        }
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </MainLayout>
        </Router>
    );
};

export default AppRouter;
