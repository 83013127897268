import { lazy } from 'react';

const Login = lazy(() => import('../screens/Authentication/Login'));
const ResetPassword = lazy(() => import('../screens/Authentication/ResetPassword'));
const Register = lazy(() => import('../screens/Authentication/Register'));
const Home = lazy(() => import('../screens/HomePage'));
const Catalog = lazy(() => import('../screens/Catalog'));
const SingleProduct = lazy(() => import('../screens/Catalog/SingleProduct'));
const About = lazy(() => import('../screens/About'));
const Literature = lazy(() => import('../screens/Resources/Literature'));
const TechnicalData = lazy(() => import('../screens/Resources/TechnicalData'));
const CrossReference = lazy(() => import('../screens/Resources/CrossReference'));
const News = lazy(() => import('../screens/Resources/News'));
const NewsSingle = lazy(() => import('../screens/Resources/News/NewsSingle'));
const Certifications = lazy(() => import('../screens/Resources/Certifications'));
const Faq = lazy(() => import('../screens/Resources/Faq'));
const Contact = lazy(() => import('../screens/Contact'));
const OurSolutions = lazy(() => import('../screens/OurSolutions'));
const Privacy = lazy(() => import('../screens/Privacy'));
const TermsOfService = lazy(() => import('../screens/TermsOfService'));

export default [
    {
        path: '/login',
        key: 'LOGIN',
        component: Login,
    },
    {
        path: '/reset-password',
        key: 'RESET_PASSWORD',
        component: ResetPassword,
    },
    {
        path: '/register',
        key: 'REGISTER',
        component: Register,
    },
    {
        path: '/',
        key: 'HOME',
        component: Home,
    },
    {
        path: '/catalog/category/*',
        key: 'CATALOG',
        component: Catalog,
    },
    {
        path: '/catalog/product/:productName',
        key: 'SINGLE_PRODUCT',
        component: SingleProduct,
    },
    {
        path: '/about',
        key: 'ABOUT',
        component: About,
    },
    {
        path: '/literature',
        key: 'LITERATURE',
        component: Literature,
    },
    {
        path: '/technical-data',
        key: 'TECHNICAL_DATA',
        component: TechnicalData,
    },
    {
        path: '/product-cross-reference',
        key: 'CROSS_REFERENCE',
        component: CrossReference,
    },
    {
        path: '/news',
        key: 'NEWS',
        component: News,
    },
    {
        path: '/news-single',
        key: 'NEWS_SINGLE',
        component: NewsSingle,
    },
    {
        path: '/certifications',
        key: 'CERTIFICATIONS',
        component: Certifications,
    },
    {
        path: '/faq',
        key: 'FAQ',
        component: Faq,
    },
    {
        path: '/contact',
        key: 'CONTACT',
        component: Contact,
    },
    {
        path: '/our-solutions',
        key: 'OUR_SOLUTIONS',
        component: OurSolutions,
    },
    {
        path: '/privacy',
        key: 'PRIVACY',
        component: Privacy,
    },
    {
        path: '/terms-of-use',
        key: 'TERMS_OF_SERVICE',
        component: TermsOfService,
    },
];
