import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubmittalService from '../../../services/SubmittalService';
import { extractError } from '../../../helpers/api';

const model = {
    data: null,
    loading: false,
    error: null,
};

export const getSubmittalPreview = createAsyncThunk(
    'submittal/getSubmittalPreview',
    async (submittalId, { rejectWithValue }) => {
        try {
            const response = await SubmittalService.getSubmittalPreview(submittalId);
            return response.data;
        } catch (error) {
            return rejectWithValue(extractError(error));
        }
    },
);

const viewSubmittalSlice = createSlice({
    name: 'viewSubmittal',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get submittal preivew
        builder.addCase(getSubmittalPreview.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getSubmittalPreview.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getSubmittalPreview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default viewSubmittalSlice.reducer;
