import BaseService from './BaseService';

class NewsService extends BaseService {
    getNewsWithQuery = (search, date) => this.get(`/news-articles?q=${search}&date=${date}`);

    getNews = () => this.get('/news-articles');

    getSingleNews = (id) => this.get(`/view-news?news_id=${id}`);

    getListOfYearsAndTags = () => this.get('/news-articles-archives');
}

export default new NewsService();
