import BaseService from './BaseService';

class SubmittalService extends BaseService {
    submitProjectData = (data) => this.post('/create-project', data);

    createOrUpdateSubmittal = (data) => this.post('/create-update-submittal', data);

    submitCoverLetterData = (data) => this.post('/submit-cover-letter', data);

    getTreeCategoryList = () => this.get('/category-tree-view');

    viewProject = (projectId) => this.get(`/view-project/${projectId}`);

    updateProject = (projectId, data) => this.post(`/edit-project/${projectId}`, data);

    getProductsByCategory = (data) => this.post(
        `/get-products-by-category?page=${data.page}&per_page=${data.per_page}`,
        { search: data.search, category_ids: data.category_ids } || {},
    );

    getProjectListOptions = () => this.get('/get-project-list-options');

    getSubmittalPreview = (submittalId) => this.get(`/get-submittal-preview/${submittalId}`);

    viewSubmittal = (submittalId) => this.get(`/view-submittal/${submittalId}`);

    deleteSubmittal = (submittalId) => this.delete(`/delete-submittal/${submittalId}`);
}

export default new SubmittalService();
