import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MenuService from '../../services/MenuService';
import { extractError } from '../../helpers/api';

const model = {
    headerMenu: [],
    footerMenu: [],
    catalogMenu: [],
    productsBySectorMenu: [],
    loading: false,
    error: null,
};

export const getMenu = createAsyncThunk(
    'menu/getMenu',
    async (_, thunkApi) => {
        try {
            const response = await MenuService.getMenu();
            const {header_menu: headerMenu, footer_menu: footerMenu} = response.data;
            return { headerMenu, footerMenu };
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getMenuCategories = createAsyncThunk(
    'menu/getMenuCategories',
    async (_, thunkApi) => {
        try {
            const response = await MenuService.getMenuCategories();
            const {
                catalog: catalogMenu,
                products_by_sector: productsBySectorMenu,
            } = response.data;
            return { catalogMenu, productsBySectorMenu };
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const menuSlice = createSlice({
    name: 'menu',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get menu
        builder.addCase(getMenu.pending, (state) => {
            state.loading = true;
        }).addCase(getMenu.fulfilled, (state, action) => {
            state.loading = false;
            state.headerMenu = action.payload.headerMenu;
            state.footerMenu = action.payload.footerMenu;
        }).addCase(getMenu.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // get menu categories
        builder.addCase(getMenuCategories.pending, (state) => {
            state.loading = true;
        }).addCase(getMenuCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.catalogMenu = action.payload.catalogMenu;
            state.productsBySectorMenu = action.payload.productsBySectorMenu;
        }).addCase(getMenuCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default menuSlice.reducer;
