import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DynamicDataService from '../../services/DynamicDataService';
import { extractError } from '../../helpers/api';

const model = {
    solutions: [],
    boardMembers: [],
    executiveLeadership: [],
    seniorLeadership: [],
    certifications: [],
    faqs: [],
    literature: [],
    technicalData: [],
    literatureSuggestions: [],
    techDataSuggestions: [],
    faqSuggestions: [],
    message: '',
    loading: false,
    error: null,
};

export const getSolutions = createAsyncThunk(
    'dynamicData/getSolutions',
    async (_, thunkApi) => {
        try {
            const response = await DynamicDataService.getSolutions();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getTeamMembers = createAsyncThunk(
    'dynamicData/getTeamMembers',
    async (_, thunkApi) => {
        try {
            const response = await DynamicDataService.getTeamMembers();
            const {
                board_members: boardMembers,
                executive_leadership: executiveLeadership,
                senior_leadership: seniorLeadership,
            } = response.data;
            return {
                boardMembers,
                executiveLeadership,
                seniorLeadership,
            }
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getCertifications = createAsyncThunk(
    'dynamicData/getCertifications',
    async (_, thunkApi) => {
        try {
            const response = await DynamicDataService.getCertifications();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getFaqs = createAsyncThunk(
    'dynamicData/getFaqs',
    async (data, thunkApi) => {
        const { search } = data;
        if (data.search) {
            try {
                const response = await DynamicDataService.getFaqsWithQuery(search);
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        } else {
            try {
                const response = await DynamicDataService.getFaqs();
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        }
    },
);

export const getFaqSuggestions = createAsyncThunk(
    'dynamicData/getFaqSuggestions',
    async (data, thunkApi) => {
        try {
            const response = await DynamicDataService.getFaqSuggestions(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getLiterature = createAsyncThunk(
    'dynamicData/getLiterature',
    async (data, thunkApi) => {
        const { search } = data;
        if (data.search) {
            try {
                const response = await DynamicDataService.getLiteratureWithQuery(search);
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        } else {
            try {
                const response = await DynamicDataService.getLiterature();
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        }
    },
);

export const getTechnicalData = createAsyncThunk(
    'dynamicData/getTechnicalData',
    async (data, thunkApi) => {
        const { search } = data;
        if (data.search) {
            try {
                const response = await DynamicDataService.getTechnicalDataWithQuery(search);
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        } else {
            try {
                const response = await DynamicDataService.getTechnicalData();
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        }
    },
);

export const getLiteratureSuggestions = createAsyncThunk(
    'dynamicData/getLiteratureSuggestions',
    async (data, thunkApi) => {
        try {
            const response = await DynamicDataService.getLiteratureSuggestions(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getTechDataSuggestions = createAsyncThunk(
    'dynamicData/getTechDataSuggestions',
    async (data, thunkApi) => {
        try {
            const response = await DynamicDataService.getTechnicalDataSuggestions(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const sendUserDataToDownloadPdf = createAsyncThunk(
    'dynamicData/sendUserDataToDownloadPdf',
    async (data, thunkApi) => {
        try {
            await DynamicDataService.downloadPdf(data);
            return 'Success';
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const dynamicDataSlice = createSlice({
    name: 'dynamicData',
    initialState: model,
    reducers: {
        clearDownloadPdfMessage: (state) => {
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        // get solutions
        builder.addCase(getSolutions.pending, (state) => {
            state.loading = true;
        }).addCase(getSolutions.fulfilled, (state, action) => {
            state.loading = false;
            state.solutions = action.payload;
        }).addCase(getSolutions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get team members
        builder.addCase(getTeamMembers.pending, (state) => {
            state.loading = true;
        }).addCase(getTeamMembers.fulfilled, (state, action) => {
            state.loading = false;
            state.boardMembers = action.payload.boardMembers;
            state.executiveLeadership = action.payload.executiveLeadership;
            state.seniorLeadership = action.payload.seniorLeadership;
        }).addCase(getTeamMembers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // get certifications
        builder.addCase(getCertifications.pending, (state) => {
            state.loading = true;
        }).addCase(getCertifications.fulfilled, (state, action) => {
            state.loading = false;
            state.certifications = action.payload;
        }).addCase(getCertifications.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get faqs
        builder.addCase(getFaqs.pending, (state) => {
            state.loading = true;
        }).addCase(getFaqs.fulfilled, (state, action) => {
            state.loading = false;
            state.faqs = action.payload;
        }).addCase(getFaqs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get suggestions for faqs
        builder.addCase(getFaqSuggestions.pending, (state) => {
            state.loading = true;
        }).addCase(getFaqSuggestions.fulfilled, (state, action) => {
            state.loading = false;
            state.faqSuggestions = action.payload;
        }).addCase(getFaqSuggestions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get literature
        builder.addCase(getLiterature.pending, (state) => {
            state.loading = true;
        }).addCase(getLiterature.fulfilled, (state, action) => {
            state.loading = false;
            state.literature = action.payload;
        }).addCase(getLiterature.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get technical data
        builder.addCase(getTechnicalData.pending, (state) => {
            state.loading = true;
        }).addCase(getTechnicalData.fulfilled, (state, action) => {
            state.loading = false;
            state.technicalData = action.payload;
        }).addCase(getTechnicalData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get literature suggestions
        builder.addCase(getLiteratureSuggestions.pending, (state) => {
            state.loading = true;
        }).addCase(getLiteratureSuggestions.fulfilled, (state, action) => {
            state.loading = false;
            state.literatureSuggestions = action.payload;
        }).addCase(getLiteratureSuggestions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get tech data suggestions
        builder.addCase(getTechDataSuggestions.pending, (state) => {
            state.loading = true;
        }).addCase(getTechDataSuggestions.fulfilled, (state, action) => {
            state.loading = false;
            state.techDataSuggestions = action.payload;
        }).addCase(getTechDataSuggestions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // send user data to download pdf
        builder.addCase(sendUserDataToDownloadPdf.pending, (state) => {
            state.loading = true;
        }).addCase(sendUserDataToDownloadPdf.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload;
        }).addCase(sendUserDataToDownloadPdf.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
});

export const {
    clearDownloadPdfMessage,
} = dynamicDataSlice.actions;

export default dynamicDataSlice.reducer;
