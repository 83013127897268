import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LoginService from '../../../services/AuthServices/LoginService';
import { extractError } from '../../../helpers/api';
import {
    setToken,
    setRefreshToken,
    setOtpToken,
    hasTokens,
    removeToken,
    removeRefreshToken,
    removeOtpToken,
    removeResetToken,
} from '../../../helpers/handleToken';

const model = {
    userEmail: '',
    submitting: false,
    isAuthenticated: true,
    emailVerified: false,
    step: 0,
    loggingOut: false,
    loggingOutError: null,
    message: '',
    error: null,
    resendMessageSuccess: '',
};

export const loginFirstStep = createAsyncThunk(
    'login/loginFirstStep',
    async (data, thunkApi) => {
        try {
            const response = await LoginService.loginFirstStep(data);
            const {
                two_factor_auth_enabled: secondAuth,
                access_token: accessToken,
                refresh_token: refreshToken,
                otp_token: otpToken,
            } = response.data;
            if (secondAuth) {
                setOtpToken(otpToken);
            } else {
                setToken(accessToken);
                setRefreshToken(refreshToken);
                localStorage.setItem('uniqueUserStr', accessToken);
                localStorage.setItem('timeStamp', new Date());
            }
            return response.data;
        } catch (error) {
            const errorData = extractError(error);
            if (typeof (errorData) === 'object') {
                const { otp_token: otpToken } = errorData;
                if (otpToken) {
                    setOtpToken(otpToken);
                }
            }
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const checkAuthorization = createAsyncThunk(
    'login/checkAuthorization',
    async (_, thunkApi) => {
        try {
            if (!hasTokens()) {
                throw new Error('No token');
            } else {
                return true;
            }
        } catch (error) {
            if (error.message === 'No token') {
                return false;
            }
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const loginSecondStep = createAsyncThunk(
    'login/loginSecondStep',
    async (data, thunkApi) => {
        try {
            const { email_verified: emailVerified } = data;
            delete data.email_verified;
            let response;
            if (emailVerified) {
                response = await LoginService.verify2faOtp(data);
            } else {
                response = await LoginService.verifyEmail(data);
            }
            const {
                access_token: accessToken,
                refresh_token: refreshToken,
            } = response.data;
            setToken(accessToken);
            setRefreshToken(refreshToken);
            localStorage.setItem('uniqueUserStr', accessToken);
            localStorage.setItem('timeStamp', new Date());
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const loginResendOtp = createAsyncThunk(
    'login/loginResendOtp',
    async (data, thunkApi) => {
        try {
            const { email_verified: emailVerified } = data;
            delete data.email_verified;
            let response;
            if (emailVerified) {
                response = await LoginService.loginResendOtp(data);
            } else {
                response = await LoginService.resendVerifyEmailOtp(data);
            }
            const { otp_token: otpToken } = response.data;
            setOtpToken(otpToken);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const logout = createAsyncThunk(
    'login/logout',
    async (_, thunkApi) => {
        try {
            await LoginService.logout();
            removeToken();
            removeRefreshToken();
            removeOtpToken();
            removeResetToken();
            localStorage.removeItem('uniqueUserStr');
            localStorage.removeItem('timeStamp');
            return null;
        } catch (error) {
            removeToken();
            removeRefreshToken();
            removeOtpToken();
            removeResetToken();
            localStorage.removeItem('uniqueUserStr');
            localStorage.removeItem('timeStamp');
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const loginSlice = createSlice({
    name: 'login',
    initialState: model,
    reducers: {
        resetLoginErrorState: (state) => {
            state.error = null;
        },
        clearLoginResendMessageSuccess: (state) => {
            state.resendMessageSuccess = '';
        }
    },
    extraReducers: (builder) => {
        // login first step
        builder.addCase(loginFirstStep.pending, (state) => {
            state.submitting = true;
        }).addCase(loginFirstStep.fulfilled, (state, action) => {
            state.submitting = false;
            state.step = action.payload.two_factor_auth_enabled ? 1 : 0;
            state.isAuthenticated = action.payload.two_factor_auth_enabled ? false : true;
            state.userEmail = action.payload.email;
            state.emailVerified = action.payload.email_verified;
        }).addCase(loginFirstStep.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
            state.userEmail = action.payload.email;
            state.step = !action.payload.email_verified && action.payload.otp_token ? 1 : 0;
        });

        // check authorization (first check if user has tokens saved in local storage)
        builder.addCase(checkAuthorization.pending, (state) => {
            state.isAuthenticated = true;
        }).addCase(checkAuthorization.fulfilled, (state, action) => {
            state.isAuthenticated = action.payload;
        }).addCase(checkAuthorization.rejected, (state, action) => {
            state.isAuthenticated = action.payload === 'No token' ? false : action.error;
        });

        // login second step (2fa auth or email verification)
        builder.addCase(loginSecondStep.pending, (state) => {
            state.submitting = true;
        }).addCase(loginSecondStep.fulfilled, (state) => {
            state.submitting = false;
            state.isAuthenticated = true;
            state.emailVerified = true;
        }).addCase(loginSecondStep.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });

        // login resend otp (resend 2fa otp or resend email verification otp)
        builder.addCase(loginResendOtp.pending, (state) => {
            state.submitting = true;
        }).addCase(loginResendOtp.fulfilled, (state) => {
            state.submitting = false;
            state.resendMessageSuccess = 'The new code was sent to your email address!';
        }).addCase(loginResendOtp.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });

        // logout
        builder.addCase(logout.pending, (state) => {
            state.loggingOut = true;
        }).addCase(logout.fulfilled, (state) => {
            state.loggingOut = false;
            state.isAuthenticated = false;
            state.user = null;
        }).addCase(logout.rejected, (state, action) => {
            state.loggingOut = false;
            state.isAuthenticated = false;
            state.loggingOutError = action.payload;
        });
    },
});

export const { resetLoginErrorState, clearLoginResendMessageSuccess } = loginSlice.actions;
export default loginSlice.reducer;
