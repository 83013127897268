import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { AnimatedLogoPurpleIcon } from '../../assets';
import { UserLightSmIcon } from '../../assets/icons';
import { HomeIcon } from '../../assets/userpanel';
import SearchModalButton from './SearchModalButton';
import Cart from '../Cart';

function HeaderFixed({
    activeTab,
    subMenuActive,
    isPrivacyPage,
    isNotFoundPage,
}) {
    const navigate = useNavigate();

    const menu = useSelector((state) => state.menu.headerMenu);
    const catalogMenu = useSelector((state) => state.menu.catalogMenu);
    const productsBySectorMenu = useSelector((state) => state.menu.productsBySectorMenu);
    const catalogModal = useSelector((state) => state.staticData.catalogModal);
    const productsBySectorModal = useSelector((state) => state.staticData.productsBySectorModal);
    const isAuthenticated = useSelector((state) => state.authentication.login.isAuthenticated);
    const emailVerified = useSelector((state) => state.authentication.register.emailVerified);

    // Reference to the default active link header
    const defaultActiveLinkHeader1 = $('.header-fixed .header-l.active');

    useEffect(() => {
        // Add click event listener to all menu items containing mega menus
        function handleMegaMenuClick(e) {
            e.stopPropagation();

            const menuId = e.currentTarget.id;
            // Check if the clicked menu item has a corresponding mega menu
            if ($('#mega-menu-' + menuId).length) {
                const megaMenuId = 'mega-menu-' + menuId;

                // Check if the mega menu is already active
                if ($('.header-fixed #' + megaMenuId).hasClass('active')) {
                    // Remove the active class from the mega menu
                    $('.header-fixed .mega-menu').removeClass('active');
                    $(this).removeClass('mega-menu-active');
                    defaultActiveLinkHeader1.addClass('active');
                } else {
                    // Mega menu is not active, proceed with opening it
                    $('.header-fixed .mega-menu').removeClass('active');
                    $('.header-fixed #' + megaMenuId).addClass('active');
                    $(this).addClass('mega-menu-active');
                    $('.header-fixed .header-l').not(this).removeClass('active');
                    $('.header-fixed .header-l').not(this).removeClass('mega-menu-active');
                }
            };
        };
        // Add click event listener to all menu items containing mega menus
        $('.header-fixed .menu-l').on('click', handleMegaMenuClick);
    
        // Cleanup event listener when the component unmounts
        return () => {
            $('.header-fixed .menu-l').off('click', handleMegaMenuClick);
        };
    }, [menu]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (!$(event.target).closest('.header').length) {
                $('.mega-menu').removeClass('active');
                $('.menu-l').removeClass('mega-menu-active');
                defaultActiveLinkHeader1.addClass('active');
            }
        };
    
        $(document).on('click', handleDocumentClick);
    
        // Cleanup function
        return () => {
            $(document).off('click', handleDocumentClick);
        };
    }, []);

    // Header fixed
    useEffect(() => {
        if (activeTab !== 'contact' && activeTab !== 'catalog' && activeTab !== 'user-panel' && activeTab !== 'auth' && subMenuActive !== 'news' && isPrivacyPage !== true) {
            // Function to insert SVG
            const insertSVG = () => {
                // Check if the SVG container div is empty before inserting
                var svgContainer = document.getElementById("svgContainer");
            
                if (svgContainer.innerHTML.trim() === "") {
                    // Create a new div and append the SVG to it
                    var newDiv = document.createElement("div");
                    newDiv.innerHTML = document.getElementById("e2H1wk6dUMV100").outerHTML;
                    svgContainer.appendChild(newDiv);
                }
            };
        
            // Function to handle scroll event
            const handleScroll = () => {
                var header = document.querySelector('.header-fixed');
                if (window.scrollY > 500) {
                    header.classList.remove('secondary');
                    header.classList.add('visible');
                    insertSVG();
                } else {
                    header.classList.add('secondary');
                    header.classList.remove('visible');
                }
            };
        
            // Attach scroll event listener
            window.addEventListener('scroll', handleScroll);
        
            // Cleanup function
            return () => {
                // Remove scroll event listener
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [activeTab]);

    return (
        <div className={`header header-fixed ${(activeTab !== 'contact' && activeTab !== 'catalog' && activeTab !== 'auth' && activeTab !== 'user-panel' && subMenuActive !== 'news' && isPrivacyPage !== true && isNotFoundPage !== true) && 'secondary'}`}>
            <div className="logo-hidden">
                <a href="/"><AnimatedLogoPurpleIcon /></a>
            </div>
            <div className="upper">
                <div className="container">
                    {(isAuthenticated || emailVerified) ? (
                        <button
                            className="user-access home"
                            onClick={() => navigate('/user-panel/dashboard')}
                        >
                            <HomeIcon />
                            <span>Dashboard</span>
                        </button>
                    ) : (
                        <button
                            className="user-access"
                            onClick={() => navigate('/login')}
                        >
                            <UserLightSmIcon />
                            <span>Login</span>
                        </button>
                    )}
                    <SearchModalButton />
                </div>
            </div>
            <div className="main-header">
                <div className="container">
                    <div className="logo" id="svgContainer">
                        <a href="/"><AnimatedLogoPurpleIcon /></a>
                    </div>
                    <div className="mobile-menu-button">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="right d-flex">
                        <ul>
                            {menu?.map((item) => {
                                if (item.menu_link) {
                                    return (
                                        <li key={item.id} className={`header-l ${activeTab === item.key && 'active'}`}>
                                            <a href={item.menu_link}>{item.menu_name}</a>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li
                                            key={item.id}
                                            className={`menu-l header-l ${activeTab === item.key && 'active'}`}
                                            id={item.key === 'products-by-sector' ? 'industry' : item.key}
                                        >
                                            <a>{item.menu_name}</a>
                                            {item.children?.length > 0 && (
                                                <div className="mega-menu sm" id={`mega-menu-${item.key}`}>
                                                    <ul>
                                                        {item.children.map((child, i) => {
                                                            return (
                                                                child.key !== 'request-a-quote' &&
                                                                <li key={i}>
                                                                    <Link to={child.menu_link}>{child.menu_name}</Link>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                        <div className="divider"></div>
                        <Cart
                            usedFor="fixed-header"
                        />
                    </div>
                </div>
            </div>
            <div className="mega-menu lg" id="mega-menu-catalog">
                <div className="container">
                    <div className="mega-menu-wrapper d-flex">
                        <div className="image d-flex">
                            <img src={catalogModal?.section_1.catalog_header.img_url} alt="metal-framing" />
                            <div className="text">
                                <h2>
                                    {catalogModal?.section_1.catalog_header.title}
                                </h2>
                                <p>
                                    {catalogModal?.section_1.catalog_header.description}
                                </p>
                                <button><a href="/catalog/category">{catalogModal?.section_1.catalog_header.button_name}</a></button>
                            </div>
                        </div>
                        <div className="content d-flex">
                            {catalogMenu.map((item) => (
                                <div className="col" key={item.category_id}>
                                    <div className="title"><a href={item.menu_link}>{item.label_name}</a></div>
                                    <ul>
                                        {item.children?.map((child) => (
                                            <li key={child.category_id}>
                                                <a href={child.menu_link}>{child.label_name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mega-menu lg" id="mega-menu-industry">
                <div className="container">
                    <div className="mega-menu-wrapper d-flex">
                        <div className="image d-flex">
                            <img
                                src={productsBySectorModal?.section_1.products_by_sector_header.img_url}
                                alt="metal-framing"
                            />
                            <div className="text">
                                <h2>{productsBySectorModal?.section_1.products_by_sector_header.title}</h2>
                                <p>{productsBySectorModal?.section_1.products_by_sector_header.description}</p>
                                <button>
                                    <a href="/our-solutions">
                                        {productsBySectorModal?.section_1.products_by_sector_header.button_name}
                                    </a>
                                </button>
                            </div>
                        </div>
                        <div className="content d-flex">
                            {productsBySectorMenu.map((item) => (
                                <div className="col" key={item.category_id}>
                                    <div className="title"><a href={item.menu_link}>{item.label_name}</a></div>
                                    <ul>
                                        {item.children?.map((child, i) => (
                                            <li key={`${child.category_id}-${i}`}>
                                                <a href={child.menu_link}>{child.label_name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderFixed;