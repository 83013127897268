import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ChevronLeftDarkIcon } from '../../assets/icons';
import { NotFound404Icon } from '../../assets/illustrations';
// import HeaderFixed from '../components/MainLayout/HeaderFixed';
import { updateMetaTitle } from '../../helpers/utils';
import { getNotFound } from '../../redux/authentication/404NotFound/404NotFoundSlice';

function NotFound() {
    const dispatch = useDispatch();

    useEffect(() => {
        updateMetaTitle('Page Not Found');
        const data = {
            url: window.location.href,
        }
        dispatch(getNotFound(data));
    }, [window.location, dispatch]);

    return (
        <div className="not-found-page">
            {/* <HeaderFixed isNotFoundPage={true} /> */}
            <section className="not-found-body bg-light">
                <div className="container">
                    <div className="illustration">
                        <NotFound404Icon />
                    </div>
                    <h2 className="font-dark">Page Not Found</h2>
                    <button className="outline-btn">
                        <a href="/" >
                            <ChevronLeftDarkIcon />
                            <span>Back to homepage</span>
                        </a>
                    </button>
                </div>
            </section>
        </div>
    );
}

export default NotFound;
