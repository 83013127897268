import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AddressService from '../../services/AddressService';
import { extractError } from '../../helpers/api';
import { upseartItem } from '../../helpers/CRUD';

const model = {
    loading: false,
    errors: null,
    addressList: [],
    addressDetails: null,
    message: '',
};

export const getAddressList = createAsyncThunk(
    'address/getAddressList',
    async (data, thunkApi) => {
        try {
            const response = await AddressService.getAddressList(data);
            return response.data;
        }
        catch (error) {
            thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getAddressDetails = createAsyncThunk(
    'address/getAddressDetails',
    async (addressId, thunkApi) => {
        try {
            const response = await AddressService.getAddressDetails(addressId);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const createNewAddress = createAsyncThunk(
    'address/createNewAddress',
    async (data, thunkApi) => {
        try {
            const { id: addressId } = data;
            let response;
            if (addressId) {
                response = await AddressService.updateAddress(data, addressId);
            } else {
                response = await AddressService.createNewAddress(data);
            }
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const addressSlice = createSlice({
    name: 'address',
    initialState: model,
    reducers: {
        clearMessage: (state) => {
            state.message = '';
        },
        clearAddressDetails: (state) => {
            state.addressDetails = null
        }
    },
    extraReducers: (builder) => {
        // get address list
        builder.addCase(getAddressList.pending, (state) => {
            state.loading = true;
        }).addCase(getAddressList.fulfilled, (state, action) => {
            state.loading = false;
            state.addressList = action.payload;
        }).addCase(getAddressList.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });
        // get address details
        builder.addCase(getAddressDetails.pending, (state) => {
            state.loading = true;
        }).addCase(getAddressDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.addressDetails = action.payload;
        }).addCase(getAddressDetails.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });
        // create new address
        builder.addCase(createNewAddress.pending, (state) => {
            state.loading = true;
        }).addCase(createNewAddress.fulfilled, (state, action) => {
            state.loading = false;
            state.addressList = upseartItem(state.addressList, action.payload);
            state.message = 'Successfully created new address!';
        }).addCase(createNewAddress.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });
    },
});

export const { clearMessage, clearAddressDetails } = addressSlice.actions;

export default addressSlice.reducer;
