import BaseService from './BaseService';

class CrossReferenceService extends BaseService {
    getCrossReferenceProductList = () => this.get('/get-product-cross-references');

    getCrossReferenceProductListWithQuery = (search) => this.get(`/get-product-cross-references?search=${search}`);

    getCrossReferenceProductSuggestions = (search) => this.get(`/get-product-cross-references-suggestions?search=${search}`);
}

export default new CrossReferenceService();
