import BaseService from './BaseService';

class AddressService extends BaseService {
    getAddressList = (data) => this.post('/get-address-list', data || {});

    getAddressDetails = (addressId) => this.get(`/view-address/${addressId}`);

    createNewAddress = (data) => this.post('/create-new-address', data);

    updateAddress = (data, addressId) => this.post(`/edit-address/${addressId}`, data);
}

export default new AddressService();
