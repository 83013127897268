import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProjectService from '../../../services/ProjectService';
import { extractError } from '../../../helpers/api';
import { deleteSubmittal } from '../create/createSlice';
const model = {
    data: [],
    totalProjects: 0,
    message: '',
    newSubmissionId: null,
    projectId: null,
    loading: false,
    error: null,
};

export const getProjectList = createAsyncThunk(
    'project/getProjectList',
    async (data, thunkApi) => {
        try {
            const response = await ProjectService.getProjectList(data);
            return response.data.map(project => ({
                ...project,
                originalSubmissions: project.submissions
            }));
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getUserTotalProjects = createAsyncThunk(
    'project/getUserTotalProjects',
    async (data, thunkApi) => {
        try {
            const response = await ProjectService.getUserTotalProjects(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const copyProject = createAsyncThunk(
    'project/copyProject',
    async (projectId, thunkApi) => {
        try {
            const response = await ProjectService.copyProject(projectId);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const duplicateSubmission = createAsyncThunk(
    'project/duplicateSubmission',
    async (data, thunkApi) => {
        try {
            const response = await ProjectService.duplicateSubmission(data.id);
            return {
                data: response.data,
                submission_type: data.type,
            }
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const requestQuote = createAsyncThunk(
    'project/requestQuote',
    async (submittalId, thunkApi) => {
        try {
            const response = await ProjectService.requestQuote(submittalId);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const convertQuoteToSubmittal = createAsyncThunk(
    'project/convertQuoteToSubmittal',
    async (quoteId, thunkApi) => {
        try {
            const response = await ProjectService.convertQuoteToSubmittal(quoteId);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const requestQuoteFromView = createAsyncThunk(
    'project/requestQuoteFromView',
    async (submittalId, thunkApi) => {
        try {
            const response = await ProjectService.requestQuoteFromView(submittalId);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const deleteProject = createAsyncThunk(
    'project/deleteProject',
    async (projectId, thunkApi) => {
        try {
            await ProjectService.deleteProject(projectId);
            return projectId;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const projectList = createSlice({
    name: 'projectList',
    initialState: model,
    reducers: {
        resetProjectStateOnSuccess: (state) => {
            state.message = '';
        },
        resetProjectStateOnError: (state) => {
            state.error = null;
        },
        filterSubmittals: (state, action) => {
            const {
                status: statusFilter,
                date: dateFilter,
                project_id: projectId,
            } = action.payload;
            const project = state.data.find((item) => item.id === projectId);
            project.submissions = [...project.originalSubmissions];
            if (statusFilter && dateFilter) {
                project.submissions = project.submissions.filter(
                    (submission) => submission.quote_requested === statusFilter
                        && new Date(submission.created_date || submission.quote_requested_date).toISOString().split('T')[0] === dateFilter);
            } else if (statusFilter) {
                const status = statusFilter === '1';
                project.submissions = project.submissions.filter((submission) => submission.quote_requested === status);
            } else if (dateFilter) {
                project.submissions = project.submissions.filter((submission) => new Date(submission.created_date || submission.quote_requested_date).toISOString().split('T')[0] === dateFilter);
            } else {
                project.submissions = project.originalSubmissions;
            }
        }
    },
    extraReducers: (builder) => {
        // project list
        builder.addCase(getProjectList.pending, (state) => {
            state.loading = true;
        }).addCase(getProjectList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(getProjectList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get user total projects
        builder.addCase(getUserTotalProjects.pending, (state) => {
            state.loading = true;
        }).addCase(getUserTotalProjects.fulfilled, (state, action) => {
            state.loading = false;
            state.totalProjects = action.payload.total;
        }).addCase(getUserTotalProjects.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // copy project
        builder.addCase(copyProject.pending, (state) => {
            state.loading = true;
        }).addCase(copyProject.fulfilled, (state, action) => {
            state.loading = false;
            state.data.push({...action.payload, originalSubmissions: action.payload.submissions});
            state.message = 'Project copied successfully!';
        }).addCase(copyProject.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // duplicate submission
        builder.addCase(duplicateSubmission.pending, (state) => {
            state.loading = true;
        }).addCase(duplicateSubmission.fulfilled, (state, action) => {
            state.loading = false;
            state.data = state.data.map((project) => {
                if (project.id === action.payload.data.id) {
                    project.submissions.push(action.payload.data.submissions[0]);
                }
                return project;
            })
            state.message = action.payload.submission_type === 'Submittal' ? 'Submission duplicated successfully!' : 'Quote duplicated successfully!';
            state.newSubmissionId = action.payload.data.submissions[0].id;
            state.projectId = action.payload.data.id;
        }
        ).addCase(duplicateSubmission.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // request a quote
        builder.addCase(requestQuote.pending, (state) => {
            state.loading = true;
        }).addCase(requestQuote.fulfilled, (state, action) => {
            state.loading = false;
            state.newSubmissionId = action.payload.quote_id;
            state.projectId = action.payload.project_id;
            state.message = 'Quote requested successfully!';
        }).addCase(requestQuote.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // convert quote to submittal
        builder.addCase(convertQuoteToSubmittal.pending, (state) => {
            state.loading = true;
        }).addCase(convertQuoteToSubmittal.fulfilled, (state, action) => {
            state.loading = false;
            state.newSubmissionId = action.payload.submittal_id;
            state.projectId = action.payload.project_id;
            state.message = 'Quote converted to submittal successfully!';
        }).addCase(convertQuoteToSubmittal.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // request a quote from view
        builder.addCase(requestQuoteFromView.pending, (state) => {
            state.loading = true;
        }).addCase(requestQuoteFromView.fulfilled, (state) => {
            state.loading = false;
            state.message = 'Quote requested successfully!';
        }).addCase(requestQuoteFromView.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // delete project
        builder.addCase(deleteProject.pending, (state) => {
            state.loading = true;
        }).addCase(deleteProject.fulfilled, (state, action) => {
            state.loading = false;
            state.data = state.data.filter((project) => project.id !== action.payload);
            state.message = 'Project deleted successfully!';
        }).addCase(deleteProject.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // Handle deleting submittal
        builder.addCase(deleteSubmittal.fulfilled, (state, action) => {
            state.data = state.data.map((project) => {
                project.submissions = project.submissions.filter((submission) => submission.id !== action.payload);
                return project;
            });
        });
    },
});

export const {
    resetProjectStateOnSuccess,
    resetProjectStateOnError,
    filterSubmittals,
} = projectList.actions;

export default projectList.reducer;
