import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DashboardService from '../../services/DashboardService';
import { extractError } from '../../helpers/api';

const model = {
    loading: false,
    error: null,
    data: null,
};

export const getDashboardData = createAsyncThunk(
    'dashboard/getDashboardData',
    async (_, thunkApi) => {
        try {
            const response = await DashboardService.getDashboardData();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get dashboard data
        builder.addCase(getDashboardData.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getDashboardData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getDashboardData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default dashboardSlice.reducer;
