import { combineReducers } from '@reduxjs/toolkit';
import createSubmittalReducer from './create/createSlice';
import viewSubmittalReducer from './view/viewSlice';
import projectListReducer from './list/listSlice';

const submittalSlice = combineReducers({
    create: createSubmittalReducer,
    view: viewSubmittalReducer,
    list: projectListReducer,
});

export default submittalSlice;
