import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RegisterService from '../../../services/AuthServices/RegisterService';
import { extractError } from '../../../helpers/api';
import {
    setToken,
    setRefreshToken,
    setOtpToken,
} from '../../../helpers/handleToken';
import { logout } from '../login/loginSlice';

const model = {
    user: null,
    message: '',
    submitting: false,
    emailVerified: false,
    step: 0,
    error: null,
    resendMessageSuccess: '',
};

export const registerFirstStep = createAsyncThunk(
    'register/registerFirstStep',
    async (data, thunkApi) => {
        try {
            const response = await RegisterService.registerFirstStep(data);
            const { otp_token: otpToken } = response.data;
            setOtpToken(otpToken);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const registerSecondStep = createAsyncThunk(
    'register/registerSecondStep',
    async (data, thunkApi) => {
        try {
            const response = await RegisterService.registerSecondStep(data);
            const {
                access_token: accessToken,
                refresh_token: refreshToken,
            } = response.data;
            setToken(accessToken);
            setRefreshToken(refreshToken);
            localStorage.setItem('uniqueUserStr', accessToken);
            localStorage.setItem('timeStamp', new Date());
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const resendVerifyEmailOtp = createAsyncThunk(
    'register/resendVerifyEmailOtp',
    async (data, thunkApi) => {
        try {
            const response = await RegisterService.resendVerifyEmailOtp(data);
            const { otp_token: otpToken } = response.data;
            setOtpToken(otpToken);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const registerSlice = createSlice({
    name: 'register',
    initialState: model,
    reducers: {
        resetRegisterErrorState:
            (state) => {
                state.error = null;
            },
        clearRegisterResendMessageSuccess: (state) => {
            state.resendMessageSuccess = '';
        },
    },
    extraReducers: (builder) => {
        // register first step
        builder.addCase(registerFirstStep.pending, (state) => {
            state.submitting = true;
        }).addCase(registerFirstStep.fulfilled, (state, action) => {
            state.submitting = false;
            state.user = action.payload;
            state.message = 'User registered successfully!';
            state.step = 1;
        }).addCase(registerFirstStep.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });
        // register second step
        builder.addCase(registerSecondStep.pending, (state) => {
            state.submitting = true;
        }).addCase(registerSecondStep.fulfilled, (state) => {
            state.submitting = false;
            state.emailVerified = true;
        }).addCase(registerSecondStep.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });
        // resend verify email otp
        builder.addCase(resendVerifyEmailOtp.pending, (state) => {
            state.submitting = true;
        }).addCase(resendVerifyEmailOtp.fulfilled, (state) => {
            state.submitting = false;
            state.resendMessageSuccess = 'The new code was sent to your email address!';
        }).addCase(resendVerifyEmailOtp.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });
        // Handle logout
        builder.addCase(logout.fulfilled, (state) => {
            state.emailVerified = false;
        });
    },
});

export const { resetRegisterErrorState, clearRegisterResendMessageSuccess } = registerSlice.actions;

export default registerSlice.reducer;
