import BaseService from './BaseService';

class ProjectService extends BaseService {
    getProjectList = (data) => this.post('/get-project-list', data || {});

    getUserTotalProjects = () => this.get('/get-user-total-projects');

    copyProject = (projectId) => this.post(`/copy-project/${projectId}`);

    duplicateSubmission = (submissionId) => this.post(`/duplicate-submission/${submissionId}`);

    requestQuote = (submittalId) => this.post(`/request-a-quote/${submittalId}`);

    convertQuoteToSubmittal = (quoteId) => this.post(`/convert-quote-to-submittal/${quoteId}`);

    requestQuoteFromView = (submittalId) => this.post(`/request-a-quote-from-view/${submittalId}`);

    deleteProject = (projectId) => this.delete(`/delete-project/${projectId}`);
}

export default new ProjectService();