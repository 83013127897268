import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import gsap from 'gsap';
import $ from 'jquery';
import { CloseMobileIcon } from '../../assets/icons';
import { LogoDarkIcon } from '../../assets';

function MobileNav ({ menu, catalogMenu, productsBySectorMenu }) {

    useEffect(() => {
        let mobileMenuTimeline = gsap.timeline();

        mobileMenuTimeline.fromTo(
            ".mobile-nav",
            {},
            {
                y: 0,
                opacity: 1,
                duration: 0.5
            }
        );
    
        mobileMenuTimeline.fromTo(
            ".mobile-nav .nav__content >ul >li",
            {},
            {
                opacity: 1,
                delay: 0.5,
                stagger: 0.07,
                y: 0,
            }, "<"
        );
    
        mobileMenuTimeline.pause();
    
        // Event listeners for opening/closing the mobile menu
        const body = document.querySelector("body");
        const menuButtons = document.querySelectorAll(".mobile-menu-button");
        const menuButtonClose = document.querySelector(".close-mobile-m");
    
        const toggleClass = () => {
            if (body.classList.contains("nav-active")) {
                body.classList.remove("nav-active");
                mobileMenuTimeline.reverse();
            } else {
                body.classList.add("nav-active");
                mobileMenuTimeline.play();
            }
        };
    
        menuButtons.forEach(button => {
            button.addEventListener("click", toggleClass);
        });
    
        menuButtonClose.addEventListener("click", toggleClass);
    
        // Cleanup function
        return () => {
            menuButtons.forEach(button => {
                button.removeEventListener("click", toggleClass);
            });
        
            menuButtonClose.removeEventListener("click", toggleClass);
        };
    }, []);

    useEffect(() => {
        const aLinks = document.querySelectorAll('.mobile-nav .nav__content .menu-l > a');
    
        const toggleDropdown = (element, contentSelector) => {
            const $element = $(element); // Convert to jQuery object
            $element.toggleClass('opened');
            $element.find(contentSelector).slideToggle(300);
        };
    
        const handleAElementClick = (event) => {
            const aLink = event.currentTarget;
            const element = aLink.closest('.menu-l');
            toggleDropdown(element, '>.sub-menu');
        };
    
        aLinks.forEach(aLink => {
            aLink.addEventListener('click', handleAElementClick);
        });

        return () => {
            aLinks.forEach(aLink => {
                aLink.removeEventListener('click', handleAElementClick);
            });
        };
    }, []);

    return (
        <div className="mobile-nav">
            <div className="nav__content">
                <div className="head">
                    <div className="main">
                        <div className="logo">
                            <a href="/"><LogoDarkIcon /></a>
                        </div>
                        <div className="close-mobile-m">
                            <CloseMobileIcon />
                        </div>
                    </div>
                </div>
                <ul>
                    {menu.map((item) => {
                        if (item.menu_link) {
                            return (
                                <li key={item.id}><a href={item.menu_link}>{item.menu_name}</a></li>
                            );
                        } else {
                            if (item.key === "resources") {
                                return (
                                    <li key={item.id} className="menu-l">
                                        <a>{item.menu_name}</a>
                                        <ul className="sub-menu">
                                            {item.children.map((child) => (
                                                child.key !== 'request-a-quote' &&
                                                <li key={child.id}><a href={child.menu_link}>{child.menu_name}</a></li>
                                            ))}
                                        </ul>
                                    </li>
                                );
                            } else {
                                return (
                                    <li className="menu-l">
                                        <a>{item.menu_name}</a>
                                        <ul className="sub-menu">
                                            {item.key === "catalog" ? (
                                                catalogMenu.map((child) => (
                                                    <li key={child.category_id} className="menu-l">
                                                        <a>{child.label_name}</a>
                                                        <ul className="sub-menu">
                                                            {child.children.map((subChild) => (
                                                                <li key={subChild.category_id}>
                                                                    <a href={subChild.menu_link}>
                                                                        {subChild.label_name}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                ))) : (
                                                productsBySectorMenu.map((child) => (
                                                    <li key={child.category_id} className="menu-l">
                                                        <a>{child.label_name}</a>
                                                        <ul className="sub-menu">
                                                            {child.children.map((subChild, i) => (
                                                                <li key={`${subChild.category_id}-${i}`}>
                                                                    <a href={subChild.menu_link}>
                                                                        {subChild.label_name}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                ))
                                            )
                                            }
                                        </ul>
                                    </li>
                                );
                            }
                        }
                    })}
                    {/* <li><Link to="/">Home</Link></li>
                    <li className="menu-l"> 
                        <a> Catalog</a>
                        <ul className="sub-menu">
                            <li className="menu-l">
                                <a> STRUT</a>
                                <ul className="sub-menu">
                                    <li><Link to="">Channel</Link></li>
                                    <li><Link to="">Welded Channel</Link></li>
                                    <li><Link to="">Grip Lock Nuts & Hardware</Link></li>
                                    <li><Link to="">Clamps</Link></li>
                                    <li><Link to="">General Fittings</Link></li>
                                    <li><Link to="">Post Bases</Link></li>
                                    <li><Link to="">Specialty Fittings</Link></li>
                                    <li><Link to="">Brackets</Link></li>
                                    <li><Link to="">Electrical</Link></li>
                                    <li><Link to="">Concrete Inserts & Accessories</Link></li>
                                </ul>
                            </li>
                            <li className="menu-l">
                                <a> Baseboard</a>
                                <ul className="sub-menu">
                                    <li><Link to="">Residential Baseboard</Link></li>
                                    <li><Link to="">Commercial Baseboard</Link></li>
                                </ul>
                            </li>
                            <li className="menu-l">
                                <a> Roof Blocks</a>
                                <ul className="sub-menu">
                                    <li><Link to="">H-Block Standard</Link></li>
                                    <li><Link to="">H-Block Mini</Link></li>
                                    <li><Link to="">H-Block Mega</Link></li>
                                </ul>
                            </li>
                            <li className="menu-l">
                                <a> Accessories</a>
                                <ul className="sub-menu">
                                    <li><Link to="">Concrete Inserts & Accessories</Link></li>
                                    <li><Link to="">Baseboard Accessories</Link></li>
                                    <li><Link to="">Rooftop Accessories</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-l">
                        <a>Products by Sector</a>
                        <ul className="sub-menu">
                            <li className="menu-l"><a> Commercial</a>
                                <ul className="sub-menu">
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory </Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                </ul>
                            </li>
                            <li className="menu-l">
                                <a> Residential</a>
                                <ul className="sub-menu">
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                </ul>
                            </li>
                            <li className="menu-l">
                                <a>Infrastructure</a>
                                <ul className="sub-menu">
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                </ul>
                            </li>
                            <li className="menu-l">
                                <a> Industrial</a>
                                <ul className="sub-menu">
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                    <li><Link to="single-product.html">Subcategory</Link></li>
                                </ul>
                            </li>
                        </ul>

                    </li>
                    <li><Link to="/about">About Haydon</Link></li>
                    <li className="menu-l">
                        <a>Resources</a>
                        <ul className="sub-menu">
                            <li><Link to="/literature">Literature</Link></li>
                            <li><Link to="/technical-data">Technical Data</Link></li>
                            <li><Link to="/request-a-quote">Request a quote</Link></li>
                            <li><Link to="/cross-reference">Cross Reference</Link></li>
                            <li><Link to="/news">News</Link></li>
                            <li><Link to="/certifications">Certifications</Link></li>
                            <li><Link to="/faqs">FAQs</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/contact">Contact</Link></li> */}
                </ul>
            </div>
        </div>
    );
};

export default MobileNav;
