import { combineReducers } from '@reduxjs/toolkit';
import loginSlice from '../authentication/login/loginSlice';
import registerSlice from '../authentication/register/registerSlice';
import resetPasswordSlice from '../authentication/resetPassword/resetPasswordSlice';
import notFoundSlice from '../authentication/404NotFound/404NotFoundSlice';

const authSlice = combineReducers({
    login: loginSlice,
    register: registerSlice,
    resetPassword: resetPasswordSlice,
    notFound: notFoundSlice,
});

export default authSlice;
