import BaseService from '../../services/BaseService';

class ResetPasswordService extends BaseService {
    resetPasswordFirstStep = (data) => this.post('/forgot-password-request', data);

    resetPasswordSecondStep = (data) => this.post('/verify-forgot-password-otp', data);

    resetPasswordThirdStep = (data) => this.post('/reset-password', data);

    resetPasswordResendOtp = (data) => this.post('/resend-forgot-password-otp', data);
}

export default new ResetPasswordService();
