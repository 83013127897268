import BaseService from './BaseService';

class FavoriteService extends BaseService {
    getFavoriteList = (data) => this.post(`/get-favorite-products?page=${data.page}&per_page=${data.per_page}`, data || {});

    addOrRemoveFavorite = (data) => this.post('/add-or-remove-product-from-favorites', data);

    getTreeCategoryList = () => this.get('/favorite-category-tree-view');
}

export default new FavoriteService();
