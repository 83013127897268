import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CrossReferenceService from '../../services/CrossReferenceService';
import { extractError } from '../../helpers/api';

const model = {
    data: [],
    suggestions: [],
    loading: false,
    error: null,
};

export const getCrossReferenceProductList = createAsyncThunk(
    'crossReference/getCrossReferenceProductList',
    async (data, thunkApi) => {
        const { search } = data;
        if (data.search) {
            try {
                const response = await CrossReferenceService.getCrossReferenceProductListWithQuery(search);
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        } else {
            try {
                const response = await CrossReferenceService.getCrossReferenceProductList();
                return response.data;
            } catch (error) {
                thunkApi.rejectWithValue(extractError(error));
            }
        }
    },
);

export const getCrossReferenceProductSuggestions = createAsyncThunk(
    'crossReference/getCrossReferenceProductSuggestions',
    async (data, thunkApi) => {
        try {
            const response = await CrossReferenceService.getCrossReferenceProductSuggestions(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const crossReferenceSlice = createSlice({
    name: 'crossReference',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get cross reference product list
        builder.addCase(getCrossReferenceProductList.pending, (state) => {
            state.loading = true;
        }).addCase(getCrossReferenceProductList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(getCrossReferenceProductList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // get cross reference product suggestions
        builder.addCase(getCrossReferenceProductSuggestions.pending, (state) => {
            state.loading = true;
        }).addCase(getCrossReferenceProductSuggestions.fulfilled, (state, action) => {
            state.loading = false;
            state.suggestions = action.payload;
        }).addCase(getCrossReferenceProductSuggestions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default crossReferenceSlice.reducer;
