import BaseService from '../../services/BaseService';

class RegisterService extends BaseService {
    registerFirstStep = (data) => this.post('/register', data);

    registerSecondStep = (data) => this.post('/verify-email-otp', data);

    resendVerifyEmailOtp = (data) => this.post('/resend-verify-email-otp', data);

    getCountryListOptions = () => this.get('/get-register-country-list');

    getCompanyListOptions = () => this.get('/get-register-company-list');
}

export default new RegisterService();
