import axios from 'axios';
import {
    getToken,
    getRefreshToken,
    removeToken,
    removeRefreshToken,
    setToken,
    setRefreshToken,
} from '../helpers/handleToken';

class BaseService {
    constructor(baseUrl = process.env.REACT_APP_API_URL) {
        this.token = getToken();
        this.isRefreshing = false;
        this.pendingRequests = [];

        this.api = axios.create({
            baseURL: baseUrl,
            headers: { 'Content-Type': 'application/json' },
        });

        // Interceptor to add Authorization header to each request
        this.api.interceptors.request.use((config) => {
            const token = getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        // Interceptor to handle response and error handling
        this.api.interceptors.response.use(
            (response) => response.data,
            async (error) => {
                const originalRequest = error.config;

                if (error.response && error.response.status === 401 && !originalRequest._retry) {
                    if (!this.isRefreshing) {
                        this.isRefreshing = true;
                        originalRequest._retry = true;
                        try {
                            const newAccessToken = await this.refreshAccessToken();

                            // Retry all the requests in the queue with the new token
                            this.pendingRequests.forEach((callback) => callback(newAccessToken));
                            this.pendingRequests = [];

                            return this.api(originalRequest);
                        } catch (refreshError) {
                            removeToken();
                            removeRefreshToken();
                            window.location.reload();
                        } finally {
                            this.isRefreshing = false;
                        }
                    }

                    // Queue the requests while the token is being refreshed
                    return new Promise((resolve) => {
                        this.addRequestToQueue((newAccessToken) => {
                            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                            resolve(this.api(originalRequest));
                        });
                    });
                }

                return Promise.reject(error);
            },
        );
    }

    addRequestToQueue(callback) {
        this.pendingRequests.push(callback);
    }

    async refreshAccessToken() {
        const newTokenResponse = await this.api.post('/refresh-token', {
            refresh_token: getRefreshToken(),
        });

        const {
            access_token: accessToken,
            refresh_token: refreshToken,
        } = newTokenResponse.data;

        setToken(accessToken);
        setRefreshToken(refreshToken);
        localStorage.setItem('uniqueUserStr', accessToken);
        localStorage.setItem('timeStamp', new Date());

        return accessToken;
    }

    // HTTP methods
    get = (url, options) => this.api.get(url, options);

    post = (url, data, options) => this.api.post(url, data, options);

    put = (url, data, options) => this.api.put(url, data, options);

    patch = (url, data, options) => this.api.patch(url, data, options);

    delete = (url, options) => this.api.delete(url, options);
}

export default BaseService;
