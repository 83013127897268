import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import NotFoundService from '../../../services/AuthServices/404notFoundService';
import { extractError } from '../../../helpers/api';

const model = {
    loading: false,
    error: null,
    notFoundMessage: '',
}

export const getNotFound = createAsyncThunk(
    'notFound',
    async (_, thunkApi) => {
        try {
            const response = await NotFoundService.get404NotFound();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const notFoundSlice = createSlice({
    name: 'notFound',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // not found
        builder.addCase(getNotFound.pending, (state) => {
            state.loading = true;
        }).addCase(getNotFound.fulfilled, (state, action) => {
            state.loading = false;
            state.notFoundMessage = action.payload;
        }).addCase(getNotFound.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default notFoundSlice.reducer;
