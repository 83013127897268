import BaseService from './BaseService';

class CatalogService extends BaseService {
    getCategories = () => this.get('/categories');

    getProductList = (filters) => this.post(`/products-by-category`, filters || { });

    getProductListAsAuthUser = (filters) => this.post(`/products-by-category-as-auth-user`, filters || { });
    
    getProductListSearch = (filters) => this.get(`/search-products-by-name?search=${filters}`, filters || { });
    
    viewProduct = (data) => this.post('/get-product', data);

    viewProductAsAuthUser = (data) => this.post('/get-product-as-auth-user', data);
}

export default new CatalogService();
