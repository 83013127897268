import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContactService from '../../services/ContactService';
import { extractError } from '../../helpers/api';

const model = {
    contactForm: [],
    message: '',
    loading: false,
    error: null,
}

export const getContactForm = createAsyncThunk(
    'contact/getContactForm',
    async (_, thunkApi) => {
        try {
            const response = await ContactService.getContactForm();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const submitContactForm = createAsyncThunk(
    'contact/submitContactForm',
    async (data, thunkApi) => {
        try {
            await ContactService.submitContactForm(data);
            return 'success';
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const contactSlice = createSlice({
    name: 'contact',
    initialState: model,
    reducers: {
        clearMessage: (state) => {
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        // get contact form
        builder.addCase(getContactForm.pending, (state) => {
            state.loading = true;
        }).addCase(getContactForm.fulfilled, (state, action) => {
            state.loading = false;
            state.contactForm = action.payload;
        }).addCase(getContactForm.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // submit contact form
        builder.addCase(submitContactForm.pending, (state) => {
            state.loading = true;
        }).addCase(submitContactForm.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload;
        }).addCase(submitContactForm.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { clearMessage } = contactSlice.actions;

export default contactSlice.reducer;
